//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// $gray-1000: #4F1964 !default; // Morado

$gray-1000: #330644 !default;
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 1px !default;
$table-cell-padding-y-sm: 0.5rem !default;
$table-cell-padding-x-sm: 1px !default;
